<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <!-- <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col> -->
    </el-row>
    <div>
      <el-button class="but" type="primary" @click="showAddProduct"
      v-auth="this.per.PRODUCT_ADD"
        >添加套餐</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="套餐名称" prop="goodsName"></el-table-column>
      <el-table-column label="代理商" prop="operationId" v-operation></el-table-column>
      <el-table-column
        label="套餐原价(元)"
        prop="goodsMarketPrice"
        align="right"
      >
        <template #default="scope">
          <span>{{ scope.row.goodsMarketPrice / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="套餐现价(元)" prop="goodsPrice" align="right">
        <template #default="scope">
          <span>{{ scope.row.goodsPrice / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="isOnSale">
        <template #default="scope">
          <eb-badge
            :list="this.const.PUTWAY"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>
      
      <el-table-column label="套餐时效(天)" prop="useday">
        <template #default="scope">
          <span v-if="scope.row.type === 3">{{scope.row.canUseDay}}</span>
          <span v-else>{{scope.row.useday}}</span>
        </template>
      </el-table-column>

      <el-table-column label="套餐类型" prop="type">
        <template #default="scope">
          <eb-badge
            :list="this.const.PRODUCT_TYPE"
            :target="scope.row.type"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="套餐描述" prop="goodsTitle"></el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span class="option option-primary" @click="showEditModal(scope.row)" v-auth="this.per.PRODUCT_UPDATA">
            编辑
          </span>
          <span
            class="option option-primary"
            v-auth="this.per.PRODUCT_PUT_WAY"
            @click="
              operationProduct(scope.row.id, this.const.PUTWAY_CODE.PUTWAY)
            "
          >
            上架
          </span>
          <span
            class="option option-primary"
            v-auth="this.per.PRODUCT_PUT_WAY"
            @click="
              operationProduct(scope.row.id, this.const.PUTWAY_CODE.SOLD_OUT)
            "
          >
            下架
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageNo"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submit"
    ></EditModal>
  </div>
</template>

<script>
import {
  fetchProductList,
  addProduct,
  updateProduct,
  disabledProduct,
  ableProduct,
} from "@/api/product";
import EditModal from "./component/EditModal";
import { areas } from "@/common/area.js";
export default {
  components: { EditModal },
  data() {
    return {
      
      dataSource: [{ id: 1 }],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        
      }, // 查询条件

      modalData: {},

      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
        MAP_MODAL: "MAP_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    // 上架下架套餐
    operationProduct(id, type) {
      let result = ableProduct;
      if(type == this.const.PUTWAY_CODE.SOLD_OUT){
        result = disabledProduct;
      }

      result({
        id: id,
      }).then((res) => {
        this.$message.success("操作成功");
        this.getList();
      });
    },

    showAddProduct() {
      this.modalData = {
        type: this.const.PRODUCT_TYPE_CODE.PRODUCT_TYPE_CODE,
      };
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showEditModal(row) {
      this.modalData = this.deepClone(row);
      this.modalData.goodsPrice = this.util.formatNum(
        this.modalData.goodsPrice / 100,
        3
      );
      this.modalData.goodsMarketPrice = this.util.formatNum(
        this.modalData.goodsMarketPrice / 100,
        3
      );
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submit(data) {
      let request = updateProduct;
      let msg = "修改成功";
      if (this.validatenull(data.id)) {
        request = addProduct;
        msg = "添加成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.getList();
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchProductList({...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
